export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  PROJECT_HOLDER = 'ROLE_PROJECT_HOLDER',
  BUYER = 'ROLE_BUYER',
  VVB = 'ROLE_VVB',
  AGENT = 'ROLE_AGENT',
  BACKEND = 'ROLE_BACKEND',
  CONSULTANT = 'ROLE_CONSULTANT',
  LEGAL_REPRESENTATIVE = 'ROLE_LEGAL_REPRESENTATIVE',
}
