import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["textContent"]
const _hoisted_17 = ["textContent"]
const _hoisted_18 = ["textContent"]
const _hoisted_19 = ["textContent"]
const _hoisted_20 = ["textContent"]
const _hoisted_21 = ["textContent"]
const _hoisted_22 = ["textContent"]
const _hoisted_23 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_dropdown_divider = _resolveComponent("b-dropdown-divider")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_dropdown_divider, null, {
      default: _withCtx(() => [
        _createTextVNode("--------ONBOARDING--------")
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/organization" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.organization'))
        }, null, 8 /* PROPS */, _hoisted_1)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/organization-document" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.organizationDocument'))
        }, null, 8 /* PROPS */, _hoisted_2)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/billing" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.billing'))
        }, null, 8 /* PROPS */, _hoisted_3)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/ormex-account" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.ormexAccount'))
        }, null, 8 /* PROPS */, _hoisted_4)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_divider, null, {
      default: _withCtx(() => [
        _createTextVNode("---------PROJECT----------")
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/project" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.project'))
        }, null, 8 /* PROPS */, _hoisted_5)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/project-photo" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.projectPhoto'))
        }, null, 8 /* PROPS */, _hoisted_6)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/project-document" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.projectDocument'))
        }, null, 8 /* PROPS */, _hoisted_7)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/ecosystemic-benefit" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.ecosystemicBenefit'))
        }, null, 8 /* PROPS */, _hoisted_8)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/sdg-evaluation-criteria" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.sdgEvaluationCriteria'))
        }, null, 8 /* PROPS */, _hoisted_9)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/sdg" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.sdg'))
        }, null, 8 /* PROPS */, _hoisted_10)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/pdd" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.pdd'))
        }, null, 8 /* PROPS */, _hoisted_11)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/project-zone" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.projectZone'))
        }, null, 8 /* PROPS */, _hoisted_12)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/annual-estimated-carbon" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.annualEstimatedCarbon'))
        }, null, 8 /* PROPS */, _hoisted_13)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/project-validation-verification-document" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.projectValidationVerificationDocument'))
        }, null, 8 /* PROPS */, _hoisted_14)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/certification" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.certification'))
        }, null, 8 /* PROPS */, _hoisted_15)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/issuance-batch" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.issuanceBatch'))
        }, null, 8 /* PROPS */, _hoisted_16)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/issuance" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.issuance'))
        }, null, 8 /* PROPS */, _hoisted_17)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/issuance-batch-document" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.issuanceBatchDocument'))
        }, null, 8 /* PROPS */, _hoisted_18)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_divider, null, {
      default: _withCtx(() => [
        _createTextVNode("-----------DEAL-----------")
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/transfer" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.transfer'))
        }, null, 8 /* PROPS */, _hoisted_19)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/position" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.position'))
        }, null, 8 /* PROPS */, _hoisted_20)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/position-range" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.positionRange'))
        }, null, 8 /* PROPS */, _hoisted_21)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_divider, null, {
      default: _withCtx(() => [
        _createTextVNode("-----------MISC-----------")
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/favorite" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.favorite'))
        }, null, 8 /* PROPS */, _hoisted_22)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_divider, null, {
      default: _withCtx(() => [
        _createTextVNode("-----------OLD------------")
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/cobenefit" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.cobenefit'))
        }, null, 8 /* PROPS */, _hoisted_23)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here ")
  ]))
}