import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Organization = () => import('@/entities/organization/organization.vue');
const OrganizationUpdate = () => import('@/entities/organization/organization-update.vue');
const OrganizationDetails = () => import('@/entities/organization/organization-details.vue');

const Cobenefit = () => import('@/entities/cobenefit/cobenefit.vue');
const CobenefitUpdate = () => import('@/entities/cobenefit/cobenefit-update.vue');
const CobenefitDetails = () => import('@/entities/cobenefit/cobenefit-details.vue');

const ProjectDocument = () => import('@/entities/project-document/project-document.vue');
const ProjectDocumentUpdate = () => import('@/entities/project-document/project-document-update.vue');
const ProjectDocumentDetails = () => import('@/entities/project-document/project-document-details.vue');

const Project = () => import('@/entities/project/project.vue');
const ProjectUpdate = () => import('@/entities/project/project-update.vue');
const ProjectDetails = () => import('@/entities/project/project-details.vue');

const Certification = () => import('@/entities/certification/certification.vue');
const CertificationUpdate = () => import('@/entities/certification/certification-update.vue');
const CertificationDetails = () => import('@/entities/certification/certification-details.vue');

const OrganizationDocument = () => import('@/entities/organization-document/organization-document.vue');
const OrganizationDocumentUpdate = () => import('@/entities/organization-document/organization-document-update.vue');
const OrganizationDocumentDetails = () => import('@/entities/organization-document/organization-document-details.vue');

const OrmexAccount = () => import('@/entities/ormex-account/ormex-account.vue');
const OrmexAccountUpdate = () => import('@/entities/ormex-account/ormex-account-update.vue');
const OrmexAccountDetails = () => import('@/entities/ormex-account/ormex-account-details.vue');

const Issuance = () => import('@/entities/issuance/issuance.vue');
const IssuanceUpdate = () => import('@/entities/issuance/issuance-update.vue');
const IssuanceDetails = () => import('@/entities/issuance/issuance-details.vue');

const Transfer = () => import('@/entities/transfer/transfer.vue');
const TransferUpdate = () => import('@/entities/transfer/transfer-update.vue');
const TransferDetails = () => import('@/entities/transfer/transfer-details.vue');

const Position = () => import('@/entities/position/position.vue');
const PositionUpdate = () => import('@/entities/position/position-update.vue');
const PositionDetails = () => import('@/entities/position/position-details.vue');

const Sdg = () => import('@/entities/sdg/sdg.vue');
const SdgUpdate = () => import('@/entities/sdg/sdg-update.vue');
const SdgDetails = () => import('@/entities/sdg/sdg-details.vue');

const ProjectPhoto = () => import('@/entities/project-photo/project-photo.vue');
const ProjectPhotoUpdate = () => import('@/entities/project-photo/project-photo-update.vue');
const ProjectPhotoDetails = () => import('@/entities/project-photo/project-photo-details.vue');

const Billing = () => import('@/entities/billing/billing.vue');
const BillingUpdate = () => import('@/entities/billing/billing-update.vue');
const BillingDetails = () => import('@/entities/billing/billing-details.vue');

const EcosystemicBenefit = () => import('@/entities/ecosystemic-benefit/ecosystemic-benefit.vue');
const EcosystemicBenefitUpdate = () => import('@/entities/ecosystemic-benefit/ecosystemic-benefit-update.vue');
const EcosystemicBenefitDetails = () => import('@/entities/ecosystemic-benefit/ecosystemic-benefit-details.vue');

const SdgEvaluationCriteria = () => import('@/entities/sdg-evaluation-criteria/sdg-evaluation-criteria.vue');
const SdgEvaluationCriteriaUpdate = () => import('@/entities/sdg-evaluation-criteria/sdg-evaluation-criteria-update.vue');
const SdgEvaluationCriteriaDetails = () => import('@/entities/sdg-evaluation-criteria/sdg-evaluation-criteria-details.vue');

const ProjectValidationVerificationDocument = () =>
  import('@/entities/project-validation-verification-document/project-validation-verification-document.vue');
const ProjectValidationVerificationDocumentUpdate = () =>
  import('@/entities/project-validation-verification-document/project-validation-verification-document-update.vue');
const ProjectValidationVerificationDocumentDetails = () =>
  import('@/entities/project-validation-verification-document/project-validation-verification-document-details.vue');

const Favorite = () => import('@/entities/favorite/favorite.vue');
const FavoriteUpdate = () => import('@/entities/favorite/favorite-update.vue');
const FavoriteDetails = () => import('@/entities/favorite/favorite-details.vue');

const ProjectSeqGenerator = () => import('@/entities/project-seq-generator/project-seq-generator.vue');
const ProjectSeqGeneratorUpdate = () => import('@/entities/project-seq-generator/project-seq-generator-update.vue');
const ProjectSeqGeneratorDetails = () => import('@/entities/project-seq-generator/project-seq-generator-details.vue');

const ProjectZone = () => import('@/entities/project-zone/project-zone.vue');
const ProjectZoneUpdate = () => import('@/entities/project-zone/project-zone-update.vue');
const ProjectZoneDetails = () => import('@/entities/project-zone/project-zone-details.vue');

const Pdd = () => import('@/entities/pdd/pdd.vue');
const PddUpdate = () => import('@/entities/pdd/pdd-update.vue');
const PddDetails = () => import('@/entities/pdd/pdd-details.vue');

const AnnualEstimatedCarbon = () => import('@/entities/annual-estimated-carbon/annual-estimated-carbon.vue');
const AnnualEstimatedCarbonUpdate = () => import('@/entities/annual-estimated-carbon/annual-estimated-carbon-update.vue');
const AnnualEstimatedCarbonDetails = () => import('@/entities/annual-estimated-carbon/annual-estimated-carbon-details.vue');

const IssuanceBatchDocument = () => import('@/entities/issuance-batch-document/issuance-batch-document.vue');
const IssuanceBatchDocumentUpdate = () => import('@/entities/issuance-batch-document/issuance-batch-document-update.vue');
const IssuanceBatchDocumentDetails = () => import('@/entities/issuance-batch-document/issuance-batch-document-details.vue');

const IssuanceBatch = () => import('@/entities/issuance-batch/issuance-batch.vue');
const IssuanceBatchUpdate = () => import('@/entities/issuance-batch/issuance-batch-update.vue');
const IssuanceBatchDetails = () => import('@/entities/issuance-batch/issuance-batch-details.vue');

const CertificationSeqGenerator = () => import('@/entities/certification-seq-generator/certification-seq-generator.vue');
const CertificationSeqGeneratorUpdate = () => import('@/entities/certification-seq-generator/certification-seq-generator-update.vue');
const CertificationSeqGeneratorDetails = () => import('@/entities/certification-seq-generator/certification-seq-generator-details.vue');

const IssuanceBatchSeqGenerator = () => import('@/entities/issuance-batch-seq-generator/issuance-batch-seq-generator.vue');
const IssuanceBatchSeqGeneratorUpdate = () => import('@/entities/issuance-batch-seq-generator/issuance-batch-seq-generator-update.vue');
const IssuanceBatchSeqGeneratorDetails = () => import('@/entities/issuance-batch-seq-generator/issuance-batch-seq-generator-details.vue');

const PositionRange = () => import('@/entities/position-range/position-range.vue');
const PositionRangeUpdate = () => import('@/entities/position-range/position-range-update.vue');
const PositionRangeDetails = () => import('@/entities/position-range/position-range-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'organization',
      name: 'Organization',
      component: Organization,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/new',
      name: 'OrganizationCreate',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:organizationId/edit',
      name: 'OrganizationEdit',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:organizationId/view',
      name: 'OrganizationView',
      component: OrganizationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cobenefit',
      name: 'Cobenefit',
      component: Cobenefit,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cobenefit/new',
      name: 'CobenefitCreate',
      component: CobenefitUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cobenefit/:cobenefitId/edit',
      name: 'CobenefitEdit',
      component: CobenefitUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cobenefit/:cobenefitId/view',
      name: 'CobenefitView',
      component: CobenefitDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-document',
      name: 'ProjectDocument',
      component: ProjectDocument,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-document/new',
      name: 'ProjectDocumentCreate',
      component: ProjectDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-document/:projectDocumentId/edit',
      name: 'ProjectDocumentEdit',
      component: ProjectDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-document/:projectDocumentId/view',
      name: 'ProjectDocumentView',
      component: ProjectDocumentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project',
      name: 'Project',
      component: Project,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/new',
      name: 'ProjectCreate',
      component: ProjectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/:projectId/edit',
      name: 'ProjectEdit',
      component: ProjectUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project/:projectId/view',
      name: 'ProjectView',
      component: ProjectDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification',
      name: 'Certification',
      component: Certification,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification/new',
      name: 'CertificationCreate',
      component: CertificationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification/:certificationId/edit',
      name: 'CertificationEdit',
      component: CertificationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification/:certificationId/view',
      name: 'CertificationView',
      component: CertificationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization-document',
      name: 'OrganizationDocument',
      component: OrganizationDocument,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization-document/new',
      name: 'OrganizationDocumentCreate',
      component: OrganizationDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization-document/:organizationDocumentId/edit',
      name: 'OrganizationDocumentEdit',
      component: OrganizationDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization-document/:organizationDocumentId/view',
      name: 'OrganizationDocumentView',
      component: OrganizationDocumentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ormex-account',
      name: 'OrmexAccount',
      component: OrmexAccount,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ormex-account/new',
      name: 'OrmexAccountCreate',
      component: OrmexAccountUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ormex-account/:ormexAccountId/edit',
      name: 'OrmexAccountEdit',
      component: OrmexAccountUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ormex-account/:ormexAccountId/view',
      name: 'OrmexAccountView',
      component: OrmexAccountDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance',
      name: 'Issuance',
      component: Issuance,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance/new',
      name: 'IssuanceCreate',
      component: IssuanceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance/:issuanceId/edit',
      name: 'IssuanceEdit',
      component: IssuanceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance/:issuanceId/view',
      name: 'IssuanceView',
      component: IssuanceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transfer',
      name: 'Transfer',
      component: Transfer,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transfer/new',
      name: 'TransferCreate',
      component: TransferUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transfer/:transferId/edit',
      name: 'TransferEdit',
      component: TransferUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'transfer/:transferId/view',
      name: 'TransferView',
      component: TransferDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position',
      name: 'Position',
      component: Position,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position/new',
      name: 'PositionCreate',
      component: PositionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position/:positionId/edit',
      name: 'PositionEdit',
      component: PositionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position/:positionId/view',
      name: 'PositionView',
      component: PositionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg',
      name: 'Sdg',
      component: Sdg,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg/new',
      name: 'SdgCreate',
      component: SdgUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg/:sdgId/edit',
      name: 'SdgEdit',
      component: SdgUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg/:sdgId/view',
      name: 'SdgView',
      component: SdgDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-photo',
      name: 'ProjectPhoto',
      component: ProjectPhoto,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-photo/new',
      name: 'ProjectPhotoCreate',
      component: ProjectPhotoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-photo/:projectPhotoId/edit',
      name: 'ProjectPhotoEdit',
      component: ProjectPhotoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-photo/:projectPhotoId/view',
      name: 'ProjectPhotoView',
      component: ProjectPhotoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'billing',
      name: 'Billing',
      component: Billing,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'billing/new',
      name: 'BillingCreate',
      component: BillingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'billing/:billingId/edit',
      name: 'BillingEdit',
      component: BillingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'billing/:billingId/view',
      name: 'BillingView',
      component: BillingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecosystemic-benefit',
      name: 'EcosystemicBenefit',
      component: EcosystemicBenefit,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecosystemic-benefit/new',
      name: 'EcosystemicBenefitCreate',
      component: EcosystemicBenefitUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecosystemic-benefit/:ecosystemicBenefitId/edit',
      name: 'EcosystemicBenefitEdit',
      component: EcosystemicBenefitUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecosystemic-benefit/:ecosystemicBenefitId/view',
      name: 'EcosystemicBenefitView',
      component: EcosystemicBenefitDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg-evaluation-criteria',
      name: 'SdgEvaluationCriteria',
      component: SdgEvaluationCriteria,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg-evaluation-criteria/new',
      name: 'SdgEvaluationCriteriaCreate',
      component: SdgEvaluationCriteriaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg-evaluation-criteria/:sdgEvaluationCriteriaId/edit',
      name: 'SdgEvaluationCriteriaEdit',
      component: SdgEvaluationCriteriaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sdg-evaluation-criteria/:sdgEvaluationCriteriaId/view',
      name: 'SdgEvaluationCriteriaView',
      component: SdgEvaluationCriteriaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-validation-verification-document',
      name: 'ProjectValidationVerificationDocument',
      component: ProjectValidationVerificationDocument,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-validation-verification-document/new',
      name: 'ProjectValidationVerificationDocumentCreate',
      component: ProjectValidationVerificationDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-validation-verification-document/:projectValidationVerificationDocumentId/edit',
      name: 'ProjectValidationVerificationDocumentEdit',
      component: ProjectValidationVerificationDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-validation-verification-document/:projectValidationVerificationDocumentId/view',
      name: 'ProjectValidationVerificationDocumentView',
      component: ProjectValidationVerificationDocumentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'favorite',
      name: 'Favorite',
      component: Favorite,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'favorite/new',
      name: 'FavoriteCreate',
      component: FavoriteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'favorite/:favoriteId/edit',
      name: 'FavoriteEdit',
      component: FavoriteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'favorite/:favoriteId/view',
      name: 'FavoriteView',
      component: FavoriteDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-seq-generator',
      name: 'ProjectSeqGenerator',
      component: ProjectSeqGenerator,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-seq-generator/new',
      name: 'ProjectSeqGeneratorCreate',
      component: ProjectSeqGeneratorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-seq-generator/:projectSeqGeneratorId/edit',
      name: 'ProjectSeqGeneratorEdit',
      component: ProjectSeqGeneratorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-seq-generator/:projectSeqGeneratorId/view',
      name: 'ProjectSeqGeneratorView',
      component: ProjectSeqGeneratorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-zone',
      name: 'ProjectZone',
      component: ProjectZone,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-zone/new',
      name: 'ProjectZoneCreate',
      component: ProjectZoneUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-zone/:projectZoneId/edit',
      name: 'ProjectZoneEdit',
      component: ProjectZoneUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'project-zone/:projectZoneId/view',
      name: 'ProjectZoneView',
      component: ProjectZoneDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pdd',
      name: 'Pdd',
      component: Pdd,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pdd/new',
      name: 'PddCreate',
      component: PddUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pdd/:pddId/edit',
      name: 'PddEdit',
      component: PddUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pdd/:pddId/view',
      name: 'PddView',
      component: PddDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-estimated-carbon',
      name: 'AnnualEstimatedCarbon',
      component: AnnualEstimatedCarbon,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-estimated-carbon/new',
      name: 'AnnualEstimatedCarbonCreate',
      component: AnnualEstimatedCarbonUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-estimated-carbon/:annualEstimatedCarbonId/edit',
      name: 'AnnualEstimatedCarbonEdit',
      component: AnnualEstimatedCarbonUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'annual-estimated-carbon/:annualEstimatedCarbonId/view',
      name: 'AnnualEstimatedCarbonView',
      component: AnnualEstimatedCarbonDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-document',
      name: 'IssuanceBatchDocument',
      component: IssuanceBatchDocument,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-document/new',
      name: 'IssuanceBatchDocumentCreate',
      component: IssuanceBatchDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-document/:issuanceBatchDocumentId/edit',
      name: 'IssuanceBatchDocumentEdit',
      component: IssuanceBatchDocumentUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-document/:issuanceBatchDocumentId/view',
      name: 'IssuanceBatchDocumentView',
      component: IssuanceBatchDocumentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch',
      name: 'IssuanceBatch',
      component: IssuanceBatch,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch/new',
      name: 'IssuanceBatchCreate',
      component: IssuanceBatchUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch/:issuanceBatchId/edit',
      name: 'IssuanceBatchEdit',
      component: IssuanceBatchUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch/:issuanceBatchId/view',
      name: 'IssuanceBatchView',
      component: IssuanceBatchDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification-seq-generator',
      name: 'CertificationSeqGenerator',
      component: CertificationSeqGenerator,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification-seq-generator/new',
      name: 'CertificationSeqGeneratorCreate',
      component: CertificationSeqGeneratorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification-seq-generator/:certificationSeqGeneratorId/edit',
      name: 'CertificationSeqGeneratorEdit',
      component: CertificationSeqGeneratorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'certification-seq-generator/:certificationSeqGeneratorId/view',
      name: 'CertificationSeqGeneratorView',
      component: CertificationSeqGeneratorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-seq-generator',
      name: 'IssuanceBatchSeqGenerator',
      component: IssuanceBatchSeqGenerator,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-seq-generator/new',
      name: 'IssuanceBatchSeqGeneratorCreate',
      component: IssuanceBatchSeqGeneratorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-seq-generator/:issuanceBatchSeqGeneratorId/edit',
      name: 'IssuanceBatchSeqGeneratorEdit',
      component: IssuanceBatchSeqGeneratorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'issuance-batch-seq-generator/:issuanceBatchSeqGeneratorId/view',
      name: 'IssuanceBatchSeqGeneratorView',
      component: IssuanceBatchSeqGeneratorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position-range',
      name: 'PositionRange',
      component: PositionRange,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position-range/new',
      name: 'PositionRangeCreate',
      component: PositionRangeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position-range/:positionRangeId/edit',
      name: 'PositionRangeEdit',
      component: PositionRangeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'position-range/:positionRangeId/view',
      name: 'PositionRangeView',
      component: PositionRangeDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
